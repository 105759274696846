
.RobertChange-button {
  background-color: #575EF9;
  box-sizing: border-box;
  border-radius: 10px;
  border: 1px solid #575EF9;
  color: #f8f7f6;
  height: 70px;
  font-weight: 800;
  width: 200px;
  font-size: $fhhealth-font-SZ;
  margin-top: 35px;
  margin-bottom: 50px;
  cursor: pointer;
  transition-duration: 0.4s;
  &:hover {
    background-color: #575EF9;
    border: 1px solid #05d09a;
  }
  @media (max-width: 1200px) {
    height: 50px;
    width: 150px;
  }
  @media (max-width: 1000px) {
    margin-top: 1px;
  }
  @media (max-width: 500px) {
    height: 56px;
    width: 321px;
    font-size: 16px;
    
   
  }

}
