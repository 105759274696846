.cookie {
  &__position {
    @include setDisplay(flex, center, center, row);
    padding: 0% 4% 0% 4%;
    position: relative;
    width: 100%;
    @media (max-width: 600px) {
     padding: 0;
    }
  }
  p {
    width: 100%;
    font-size: 20px;
    color: black;
    padding: 20px;
    margin-left: 15%;
    @media (max-width: 900px) {
      padding: 0px;
      margin-left: 2%;
      font-size: 18px;
      margin-left: 15%;
    }
    @media (max-width: 600px) {
      font-size: 14px;
    }
    @media (max-width: 500px) {
      font-size: 12px;
      color: black;
      font-weight: bolder;
    }
  }
  &__img {
    padding: 0% 0% 0% 0%;
    position: absolute;
    left: 6%;
   bottom: 30%;
    @media (max-width: 1000px) {
      display: none;
    }
  }
  &__tittle {
    @include setDisplay(flex, center, center, row);
    background-color: #ffffff;
    border-radius: 14px;
    box-shadow: 0px 0px 12px rgba(5, 208, 154, 0.1);
      padding:1% 0% 1% 0%;
    width: 100%;
  }

  &__button-c {
    background-color: #575EF9;
    border-radius: 18px;
    box-shadow: 0px 4px 12px rgba(232, 95, 0, 0.2);
    color: white;
    border: 1px solid #575EF9;
    font-size: 24px;
    text-transform: uppercase;
    font-weight: 600;
    padding: 1% 4% 1% 4%;
    margin-right: 5%;
    cursor: pointer;
    @media (max-width: 900px) {
      font-size: 14px;
      font-weight: 700;
      font-family: 'Open Sans';
      padding: 2% 4% 2% 4%;
      border-radius: 10px;
    }
    &:hover {
      background-color: #6870fd;
      border: 1px solid #05d09a;
    }
  }

  &__background-img {
    max-width: 100%;
    display: flex;
    height: calc(100%-93px);
    @media (max-width: 500px) {
      display: none;
    }
  }
}
