.exchange {
  position: relative;
  z-index: 8;
  &__shadow-exchange {
    @include setDisplay(flex, center, center, row);
    padding: 80px;
    @media (max-width: 850px) {
      flex-wrap: wrap-reverse;
    }
  }
  &__img {
    margin-left: 80px;
   
    height: 700px;
    max-width: auto;
    @media (max-width: 1600px) {
      height: 700px;
    }
    @media (max-width: 1350px) {
      height: 600px;
    }
    @media (max-width: 1100px) {
      height: 450px;
    }
    @media (max-width: 750px) {
      height: 400px;
      margin-left: 2px;
      margin-top: 25px;
    }
    @media (max-width: 650px) {
      height: 350px;
    }
    @media (max-width: 550px) {
      height: 250px;
    }
  }
  
  &__shadow-position {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin-left: 15%;
  }
  &__title {
    color: $fhhealth-color-orange;
    text-transform: uppercase;
    font-weight: bold;
    font-size: $fhhealth-font-SZ-70;
    @media (max-width: 1200px) {
      font-size: 40px;
    }
    @media (max-width: 660px) {
      font-size: 35px;
    }

    @media (max-width: 480px) {
      margin-top: 205px;
    }
  }

  &__text {
    color: #595758;
    font-size: $fhhealth-font-SZ-43;
    margin-top: 25px;
    @media (max-width: 660px) {
      font-size: $fhhealth-font-SZ-25;
    }
  }
  &__background-img {
    position: absolute;
    right: 0px;

    @media (max-width: 1600px) {
      height: 1000px;
    }
    @media (max-width: 1400px) {
      height: 800px;
    }
    @media (max-width: 700px) {
      display: none;
    }
  }
  &__background-img-mob {
    object-fit: scale-down;
    display: flex;
    display: none;
    max-width: 100%;
    @media (max-width: 500px) {
      display: inline-block;
      display: flex;
    }
  }
  &__text-dot-d {
    color: #595758;
    font-size: 48px;
    font-weight: bold;
    color: #595758;
    margin-bottom: 20px;
    @media (max-width: 500px) {
      display: none;
    }
  }
  &__text-info {
    color: #595758;
    font-size: $fhhealth-font-SZ;
    font-weight: 400;
    color: #595758;
    width: 55%;
    font-family: Montserrat;
    @media (max-width: 660px) {
      width: 95%;
      font-size: 10px;
    }
  }
  &__RobertChange-titles {
    color: #575ef9;
    text-transform: uppercase;
    p {
      font-family: Montserratblack;
      font-size: $fhhealth-font-SZ-70;

      @media (max-width: 1300px) {
        font-size: 45px;
      }
      @media (max-width: 789px) {
        font-size: 30px;
      }
      @media (max-width: 550px) {
        font-size: 22px;
      }
      @media (max-width: 510px) {
        font-size: 22px;
        line-height: 31px;
      }
      @media (max-width: 500px) {
        font-size: 24px;
        line-height: 30px;
        display: flex;
        margin-bottom: 20px;
      }
    }

    @media (max-width: 1190px) {
      font-size: 45px;
      margin-bottom: -20px;
    }
    @media (max-width: 789px) {
      font-size: 30px;
      margin-bottom: -25px;
    }
    @media (max-width: 550px) {
      font-size: 18px;
      margin-bottom: -25px;
    }
    @media (max-width: 510px) {
      font-size: 12px;
      line-height: 15px;
    }
    @media (max-width: 500px) {
      font-size: 24px;
      line-height: 30px;
      display: flex;
      p {
        margin-right: 2%;
      }
    }
  }

  &__text-info-curency {
    font-family: OpenSansExtra;
    color: #595758;
    font-size: 18px;
    font-weight: 700;
    width: 60%;

    font-family: OpenSansExtra;
    @media (max-width: 1600px) {
      width: 85%;
      font-size: 17px;
    }
    @media (max-width: 1467px) {
      width: 88%;
      font-size: 17px;
    }
    @media (max-width: 1080px) {
      width: 95%;
      font-size: 15px;
    }
    @media (max-width: 800px) {
      width: 95%;
      font-size: 12px;
    }
    @media (max-width: 570px) {
      width: 95%;
      font-size: 10px;
    }
    @media (max-width: 500px) {
      display: none;
    }
  }

  &__text-info-curency-mob {
    color: #595758;
    font-size: $fhhealth-font-SZ;
    font-weight: 400;
    color: #595758;
    display: none;
    padding: 5%;

    span {
      color: black;
      font-weight: bold;
    }
    font-family: Montserrat;

    @media (max-width: 500px) {
      display: inline-block;
    }
  }
  &__country {
    @include setDisplay(flex, center, center, row);
    flex-wrap: wrap;
    color: $fhhealth-color-orange;
    font-size: 16px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 800;
    position: relative;
    right: 10px;
    padding: 15px 0px 10px 0px;
    @media (max-width: 660px) {
      font-size: 15px;
    }
    div {
      margin: 10px;
    }
  }
}
