

html,
body {
  padding: 0;
  margin: 0;
  font-family: Montserrat;
}
h1,
h2,
h3,
h4,
h5,
p {
  padding: 0;
  margin: 0;
}
a {
  color: inherit;
  text-decoration: none;
}

html {
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;
}
