.privacy {
  
  a{
    font-weight: bolder;
    color: black;
  }
  margin-top: 7%;
  margin-bottom: 10%;
  @media (max-width: 1200px) {
    margin-top: 15%;
  }
  @media (max-width: 600px) {
 
  }
  &__text {
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    color: #595758;
    margin-top: 25px;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    text-align: start;

    p {
      margin-top: 2%;
    
    }
    h1 {
      color: #575EF9;
      margin-top: 6%;
      line-height: 28px;
    }
    h3 {
      color: #575EF9;
      margin-top: 3%;
    }
    h4 {
      color: #575EF9;
      margin-top: 2%;
    }
    div {
      margin-left: 10%;
      margin-top: 3%;
    }
    li {
      margin-top: 2%;
    }
    @media (max-width: 1068px) {
      font-size: 11px;
    }
    @media (max-width: 680px) {
      line-height: 11px;
    }
  }
  &__title {
    color: #575EF9;
    font-size: $fhhealth-font-SZ-70;
    line-height: 85.33px;
    font-family: Montserrat;
    font-weight: bolder;
    font-style: normal;
    @media (max-width: 1068px) {
      font-size: 30px;
      line-height: 33px;
    }
  }

  &__policy {
    padding-top: 9%;
    background-color: rgba(255, 255, 255, 0.3);
    text-align: center;
    backdrop-filter: blur(40px);
  }

  &__textk {
    font-size: 58px;
    color: #595758;
  }
}
