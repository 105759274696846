@font-face {
  font-family: 'Montserrats';
  src: url('/fonts/Montserrat/Montserrat-Italic-VariableFont_wght.ttf')
    format('truetype');
}
@font-face {
  font-family: 'Montserratblack';
  src: url('/fonts/Montserrat/Montserrat-Black.ttf')
    format('truetype');
}
@font-face {
  font-family: 'Montserratmedium';
  src: url('/fonts/Montserrat/Montserrat-Medium.ttf')
    format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  src: url('/fonts/Montserrat/Montserrat-VariableFont_wght.ttf')
    format('truetype');
}
@font-face {
  font-family: 'OpenSansExtra';
  src: url('/fonts/OpenSans/OpenSans-ExtraBold.ttf')
    format('truetype');
}

