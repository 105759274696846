.product {
  position: relative;
  padding: 60px 0px;
  @media (max-width: 500px) {
    padding:  0px;
  }
 &__shadow{
   position: absolute;
   bottom: 0px;
   @media (max-width: 1600px) {
    height: 500px;
  }
  @media (max-width: 1400px) {
    height: 400px;
  
  }
  @media (max-width: 700px) {
    display: none;
  }
 }
  &__reason {

    @include setDisplay(flex, center, center, row);
  }


  &__background-img {
    height: 800px;

    margin-right: 50px;
    margin-top: 10%;
    @media (max-width: 1600px) {
      height: 700px;
      margin-top: 5%;
    }
    @media (max-width: 1400px) {
      height: 600px;
      margin-top: 5%;
    }
    @media (max-width: 1200px) {
     height: 500px;
      margin-top: 0%;
    }
    @media (max-width: 1000px) {
    height: 400px;
    }
    @media (max-width: 800px) {
    height: 200px;
    }
    @media (max-width: 500px) {
      display: none;
    }
  }
   
  
}
