.header {
  position: fixed;
  top: 0;
  z-index: 99999;
  backdrop-filter: blur(40px);
  max-width: 100vw;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.3);

  &__h1 {
    color: $fhhealth-color-orange;
    font-size: $fhhealth-font-SZ-70;
    line-height: 85.33px;
    font-family: Montserrat;
    font-weight: bolder;
    font-style: normal;
    @media (max-width: 1068px) {
      font-size: 30px;
      line-height: 33px;
    }
  }
  &__context {
    @include setDisplay(flex, space-between, center, row);
    padding: 1% 0% 1% 0%;
  }

  &__services-menu {
    position: absolute;
    background-color: #f8f7f6;
    border-radius: 18px;
    margin: 1% 10% 0% 0%;
    font-size: 18px;
    font-weight: 700;
    @media (max-width: 1068px) {
      margin: 1% 15% 0% 0%;
    }
    line-height: 27px;
    right: 0px;
    z-index: 8;
    div {
      font-family: Montserrat;
      color: #595758;
      margin: 20px;
      &:hover {
        color: #2c3dac;
      }
    }
  }

  &__company-menu {
    position: absolute;
    background-color: #f8f7f6;
    border-radius: 18px;
    margin: 1% 3% 0% 0%;
    font-size: 18px;
    font-weight: 700;
    line-height: 27px;
    right: 0px;
    color: #595758;
    z-index: 8;
    div {
      font-family: Montserrat;
      margin: 20px;
      color: #595758;
      cursor: pointer;
      &:hover {
        color: #2c3dac;
      }
    }
  }

  &__company {
    margin: 0px 30px 0px 30px;
    color: gray;
    @media (max-width: $fhhealth-media-sm) {
      margin: 0px 10px 0px 10px;
    }
  }

  &__focus {
    color: gray;
    margin: 0px 40px 0px 30px;
    font-size: 18px;
     font-family:OpenSansExtra;
    //  &:hover {
    //    color: #e85f00;
    //  }
   
    @media (max-width: 745px) {
      margin: 0px 3px 0px 0px;
    }
  }

  &__isactive {
    color: #575EF9;
    ;
    margin: 0px 40px 0px 30px;
    font-size: 18px;
     font-family: OpenSansExtra;
    //  &:hover {
    //    color: #4f229d;
    //  }
   
    @media (max-width: 745px) {
      margin: 0px 3px 0px 0px;
    }
  }
  &__flag {
    padding: 15px 0px 14px 1px;
    border-radius: 35px;
    border: 1px solid #e85f00;
    font-size: $fhhealth-font-SZ-16;
    line-height: 19px;
    color: #595758;
  }

  &__top-moneyimg {
    @media (max-width: $fhhealth-media-lg) {
      margin-left: 5%;
    }

    width: $fhhealth-image-width;
    height: $fhhealth-image-height;
    margin-left: 25%;
  }

  &__right-menu {
    color: $fhhealth-color-dark-gray-border;
    @include setDisplay(flex, space-between, center, row);
    font-size: $fhhealth-font-SZ;
    padding: 0px 50px 0px 0px;
    line-height: 22px;
    cursor: pointer;
    font-weight: 800;

    @media (max-width: $fhhealth-media-sm) {
      display: none;
    }
  }

  &__button {
    font-size: 13px;
    background-color: blue;
    color: white;
    border: 1px solid blue;
    border-radius: 3px;
    padding: 5px 60px 5px 60px;
    margin-top: 15px;
    font-size: $fhhealth-font-SZ-20;

    cursor: pointer;

    &:hover {
      transition: 1s;
      background-color: rgb(86, 86, 197);
      border: 1px solid rgb(86, 86, 197);
    }
  }

  &__mobile {
    display: none;
    @media (max-width: $fhhealth-media-sm) {
      display: inline-block;
    }
  }
  &__mobile-icon{
    font-family: Montserratblack;
    @include setDisplay(flex, flex-start, center, row);
  
  img{
    margin-right: 5%;
  }
  }
  &__RobertChange-mobile-menu {
    @media (min-width: $fhhealth-media-sm) {
      display: none;
    }
    background-color: #575EF9;
    color: white;
    font-size: 18px;
    line-height: 30px;
    font-weight: 800;
    right: 0px;
    position: absolute;
    width: 60%;
    min-height: 100vh;
    z-index: 9999;
    padding: 15px;
    font-size: 20px;
  
    div {
      margin-top: 10%;
    }
  }
  &__mobile-menu-title {
    width: 45%;
  }

  &__burger-pos {
    margin-right: 15px;
    height: 18px;
    width: 20px;
  }

  &__mobile-x {
    cursor: pointer;
  }
}
