.footer {
  background-color: #fef9f3;
  font-family: Montserrat;
  line-height: 27px;
  font-size: $fhhealth-font-SZ-16;
  padding: 100px 0px 120px 14px;
  position: relative;
  z-index: 999;

  &__menu {
    @include setDisplay(flex, space-around, center, row);
    flex-wrap: wrap;
  }

  &__social-media {
    @media (max-width: $fhhealth-media-md) {
      min-width: 70%;
      flex-wrap: wrap;
    }
    @media (max-width: 500px) {
      margin-top: 5%;
        
    }

  }

  &__context {
    @include setDisplay(flex, space-around, center, row);
    padding: 38px 0px 38px 0px;
  }


  &__icons {
    @include setDisplay(flex, center, center, row);

    @media (max-width: 1000px) {
      @include setDisplay(flex, start, center, row);
    
    }
    cursor: pointer;
  }

  &__icon-size {
    padding: 10px;
  }

  &__services-title {
    margin-bottom: 10px;
    font-weight: 900;
    line-height: 24px;
    width: 100%;
    font-family: 'Montserratmedium';
  }

  &__services {
    flex-wrap: wrap;
  }

  &__company {
    @media (max-width: $fhhealth-media-md) {
      min-width: 70%;
      flex-wrap: wrap;
     
    }
    @media (max-width: 500px) {
      margin-top: 5%;
        
    }
  }

  &__top-money-img {
    margin-bottom: 15px;
    width: $fhhealth-image-width-f;
    height: $fhhealth-image-height-f;
    overflow: hidden;
  }

  &__card-top-money {
    @include setDisplay(flex, flex-start, unset, column);
    width: 40%;
    line-height: 21px;
    @media (max-width: 1390px) {
      padding: 0px 50px 0px 0px;
      width: 98%;
    }
  }

  &__right-menu {
    cursor: pointer;
    color: $fhhealth-color-dark-gray-border;
    @include setDisplay(flex, space-between, center, row);
    font-size: $fhhealth-font-SZ;
    font-weight: 800;
    line-height: 22px;
  }

  &__country {
    @include setDisplay(flex, center, flex-end, row);
    margin-left: 20px;
    @media (max-width: $fhhealth-media-extra-lg) {
      flex-wrap: wrap;
    }
  }

  &__path {
    @include setDisplay(flex, flex-start,center, row);
    line-height: 21px;
    font-weight: 700;
    margin-top: 4%;
    width: 100%;
    @media (max-width: 800px) {
      margin-top: 5%;
    }
    img {
      
      margin-right: 3%;
    }
  }
  &__position-sc {
    @include setDisplay(flex, space-between, unset, row);
    flex-wrap: wrap;
    @media (max-width: 700px) {
      margin-top: 5%;
    }
  }
  &__path2 {
    position: relative;
    top: 15%;
    width: 100%;
    line-height: 27px;
    font-weight: 700;
    @media (max-width: 700px) {
      top: 8%;
    }
    @media (max-width: 500px) {
      top: 110%;
      left: 4%;
    }

    img {
      margin-right: 3%;
    }
  }
  &__cookie-pos {
    position: fixed;
    left: 3%;
    width: 90%;
    bottom: 3%;
    animation: mymove 2s linear;
    @keyframes mymove {
      0% {
        bottom: -40%;
      }
      100% {
        bottom: 3%;
      }
    }
  }
}
