.contact {
  &__RobertChange-position {
    padding: 80px;
    @media (max-width: 700px) {
      padding: 80px 0px;
    }
    @include setDisplay(flex, space-between, center, row);
  }
  @media (max-width: 600px) {
    margin-top: 5%;
  }
  @media (max-width: 700px) {
    margin-top: 4%;
  }
  &__robo-img {
 
    height: 606px;
    @media (max-width: 1600px) {
      height: 400px;
    }
    @media (max-width: 1350px) {
      height: 300px;
    }
    @media (max-width: 1000px) {
      display: none;
    }
  }
  &__spinner {
    position: absolute;
    top: 32%;
    left: 46%;
    z-index: 99;
    @media (max-width: 600px) {
      position: relative;
      left: 0px;
      bottom: 350px;
    }
  }
  &__spinner-shadow {
    position: absolute;
    height: 100vh;
    right: 10%;
    padding: 500px;
    z-index: 9999999;
  }
  &__object-design {
    width: 100%;
  }
  &__RobertChange-input-bg {
    position: relative;
  }
  &__input {
    @include setDisplay(flex, center, center, column);
    div {
      text-align: center;
    }
  }

  &__RobertChange-input-all {
    border-radius: 50px;
    padding: 16px;
    box-shadow: 0px 7px 23px rgba(17, 45, 143, 0.19);
    border: 1px solid rgba(17, 45, 143, 0.19);
    margin-top: 4%;
    width: 90%;
    resize: vertical;
    cursor: pointer;
    font-size: 15px;
    color: #595758;
    font-family: Montserrat;
  }

  &__RobertChange-input-message {
    color: #595758;
    padding: 4% 10% 30% 2%;
    border-radius: 15px;
    box-shadow: 0px 7px 23px rgba(17, 45, 143, 0.19);
    border: 1px solid rgba(17, 45, 143, 0.19);
    margin-top: 25px;
    width: 90%;
    max-width: 100%;
    max-height: 288px;
    font-family: Montserrat;
    font-size: 16px;
    cursor: pointer;
    font-size: 15px;
  }
  &__texts-dot {
    color: #595758;
    font-weight: bold;
    color: #595758;
    font-size: 73px;
    margin-bottom: 20px;
    @media (max-width: 1000px) {
      font-size: 45px;
      line-height: 0px;
      margin-top: 17%;
    }
    @media (max-width: 700px) {
      display: none;
    }
  }
  &__RobertChange-question {
    color: #575ef9;
    font-size: 40px;
    line-height: 48.76px;
    font-family: Montserratblack;
    text-transform: uppercase;
    font-weight: 900;
    margin-bottom: 5%;

    @media (max-width: 933px) {
      font-size: 36px;
    }
    @media (max-width: 933px) {
      font-size: 26px;
    }
    @media (max-width: 627px) {
      font-size: 16px;
    }
    @media (max-width: 427px) {
      font-size: 15px;
    }
  }
  &__question-pos {
    @include setDisplay(flex, center, center, column);
  }

  &__RobertChange-info {
    @include setDisplay(flex, center, center, column);

    h1 {
      color: #575ef9;
      font-size: $fhhealth-font-SZ-70;
      line-height: 75.33px;
      font-family: Montserratblack;
      font-weight: bolder;
      font-style: normal;
      @media (max-width: 1200px) {
        font-size: 46px;
      }
      @media (max-width: 1000px) {
        font-size: 36px;
      }
      @media (max-width: 700px) {
        font-size: 21px;
        line-height: 29px;
      }
      @media (max-width: 500px) {
        font-size: 24px;
        line-height: 29px;
        font-weight: 900;
        margin: 0 auto;
      }
    }
  }
  &__info-pos {
    @include setDisplay(flex, center, center, column);
  }
  &__loc-pos {
    @include setDisplay(flex, center, center, row);
    margin-top: 3%;
    font-family: Montserrat;
    min-width: 100%;
    @media (max-width: 697px) {
      margin-top: 0px;
    }
    @media (max-width: 500px) {
      margin-top: 5%;
    }
  }
  &__lock {
    @include setDisplay(flex, center, center, row);
    font-weight: 700;
    @media (max-width: 800px) {
      font-size: 16px;
    }
    @media (max-width: 500px) {
      margin-bottom: 5%;
      margin-left: 5%;
    }
    img {
      margin-right: 5px;
    }
    a {
      @media (max-width: 500px) {
        font-size: 16px;
      }
    }
  }
  &__locl {
    @include setDisplay(flex, center, center, row);

    font-weight: 700;
    @media (max-width: 800px) {
      font-size: 10px;
    }
    @media (max-width: 500px) {
      margin-left: 5%;
    }
    img {
      margin-right: 5px;
    }
    a {
      @media (max-width: 500px) {
        font-size: 16px;
      }
    }
  }
  &__text-info-c {
    padding: 0% 15%;
    font-weight: bold;
    text-align: center;
    @media (max-width: 600px) {
      font-size: 18px;
      margin-top: 10px;
    }
    @media (max-width: 500px) {
      font-size: 16px;
      line-height: 20px;
      margin: 0 auto;
      margin-top: 2%;
    }
  }
  &__onblur-m {
    color: red;
    font-family: Montserrat;
    font-size: 16px;
    text-align: start;
    margin-top: 1%;
    @media (max-width: 600px) {
      font-size: 12px;
    }
  }
  &__fakebutton-pos {
    text-align: center;
    margin-top: 2%;
    @media (max-width: 600px) {
      margin-top: 11%;
      position: relative;
    }
  }
  &__message-img {
    display: flex;
    width: 100%;
    @media (max-width: 500px) {
      display: none;
    }
  }
  &__mesage-mob-vers {
    height: 50vh;
    display: none;
    @media (max-width: 500px) {
      display: inline-block;
    }
  }
}
