.home {
  @media (max-width: 500px) {
    background-image: none !important;
  }
  &__RobertChange-titles {
    h1 {
      font-family: OpenSansExtra;
      color: #575ef9;

      @media (max-width: 500px) {
        text-align: center;
        font-size: 30px;
      }
      @media (max-width: 470px) {
        text-align: center;
        font-size: 25px;
      }
    }

    @media (max-width: 555px) {
      top: 50px;
    }
  }
  &__shadow {
    width: 40%;

    @media (max-width: 500px) {
      position: absolute;
      top: 16%;
      width: 100%;
    }
    @media (max-width: 450px) {
      top: 15%;
    }
    @media (max-width: 415px) {
      top: 16%;
    }
    @media (max-width: 380px) {
      top: 18%;
    }
  }
  &__box {
    z-index: 9999;
    //min-height: 100vh;
    padding: 60px;
    @include setDisplay(flex, space-around, center, row);
  }

  &__money-img {
    max-width: 100%;
    display: flex;
    @media (max-width: 500px) {
      display: none;
    }
  }
  &__money-img-mob {
    width: 100%;
    padding-top: 20%;
    display: flex;
    display: none;
    @media (max-width: 500px) {
      display: inline-block;
    }
  }

  &__background {
    max-width: 100%;
    object-fit: scale-down;
    display: flex;
  }

  &__title {
    color: #595758;
    font-size: 121px;
    @media (max-width: 1900px) {
      font-size: 80px;
    }
    @media (max-width: 1400px) {
      font-size: 40px;
    }
    @media (max-width: 1150px) {
      font-size: $fhhealth-font-SZ-30;
    }
    @media (max-width: 885px) {
      font-size: 30px;
    }
    @media (max-width: 590px) {
      font-size: 18px;
      margin-top: 30px;
    }
    @media (max-width: 477px) {
      font-size: 10px;
    }
    @media (max-width: 375px) {
      font-size: 24px;
    }
  }
  &__phone-gif {
    height: 800px;

    @media (max-width: 1400px) {
      height: 400px;
    }
    @media (max-width: 1200px) {
      height: 300px;
    }
    @media (max-width: 1000px) {
      font-size: 15px;
    }
    @media (max-width: 600px) {
      display: none;
    }
  }
  &__text {
    color: #0b0a0a;
    font-family: OpenSansExtra;
    font-size: $fhhealth-font-SZ-43;
    @include setDisplay(flex, center, start, column);
    margin-top: 25px;
    @media (max-width: 1400px) {
      font-size: $fhhealth-font-SZ-25;
    }
    @media (max-width: 800px) {
      font-size: 12px;
    }
    @media (max-width: 500px) {
      margin-top: 5px;
      font-size: 22px;
      @include setDisplay(flex, center, center, column);
    }
    @media (max-width: 470px) {
      font-size: 18px;
    }
  }
}
