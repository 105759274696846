.error {
  height: 100vh;
  background-color: rgb(243, 238, 232);
  color: red;
  text-align: center;
  p {
    position: absolute;
    bottom: 50%;
    text-align: center;
    left: 40%;
    font-size: 15px;
  }
}
