.pos {
  padding: 60px;
  position: relative;
  @media (max-width: 1200px) {
    padding: 0px;
  }
}
.services {
  position: relative;
  z-index: 8;
  @include setDisplay(flex, center, center, row);
  @media (max-width: 1300px) {
    padding: 10% 0% 0% 5%;
  }
  @media (max-width: 800px) {
    padding: 18% 0% 0% 5%;
  }
  @media (max-width: 1220px) {
    @include setDisplay(flex, center, center, column);
  }

  font-family: Montserrat;
  &__section {
    position: relative;
    top: 150px;
    right: 400px;
    min-height: 100vh;
    transform-style: preserve-3d;
    transform: rotate(15deg);

    @media (max-width: 1261px) {
      display: none;
    }
  }

  &__circle {
    transform-style: preserve-3d;
    animation: animateText 10s linear infinite;

    span {
      font-weight: bold;
      color: #ab5c54;
      font-size: 18px;
      font-family: 'Courier New', Courier, monospace;
      transform-origin: center;
      transform-style: preserve-3d;
      transform: rotateY(calc(var(--i) * calc(360deg / 8))) translateZ(250px);
      position: absolute;
      top: 200px;
      right: 10px;
      padding: 5px 11px;
      left: 10px;
      @media (max-width: 1461px) {
        transform: rotateY(calc(var(--i) * calc(360deg / 8))) translateZ(240px);
        font-size: 17px;
        top: 190px;
      }
    }
  }
  @keyframes animateText {
    0% {
      transform: perspective(1000px) rotateY(360deg) rotateX(5deg)
        translateY(0px);
    }
    100% {
      transform: perspective(1000px) rotateY(0deg) rotateX(5deg) translateY(0px);
    }
  }
  &__earth {
    position: absolute;
    transform: translate(-50%, -50%);
    width: 270px;
    height: 270px;
    background-size: cover;
    border-radius: 50%;
    background-repeat: repeat-x;
    box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.3),
      0 0 60px $fhhealth-color-orange;
    animation: animateEarth 15s linear infinite;
    @media (max-width: 1461px) {
      height: 200px;
      width: 200px;
    }
    top: 30%;
  }

  @keyframes animateEarth {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: -1701px 0px;
    }
  }
  &__info {
    @include setDisplay(flex, flex-start, flex-start, column);
    padding: 5% 0% 0% 12%;
    @media (max-width: 600px) {
      padding: 0px;
      margin-top: 180px;
    }
  }

  &__reasons {
    position: relative;
  }

  &__shadow-exchange {
    width: 45%;
    position: absolute;
    right: 0px;
    top: 20%;
    @media (max-width: 830px) {
      top: 1%;
    }
  }

  &__shadow-position {
    @include setDisplay(flex, flex-start, flex-start, column);
    padding-left: 20%;
  }

  &__RobertChange-title {
    color: #575ef9;
    text-transform: uppercase;

    p {
      font-size: $fhhealth-font-SZ-70;
      font-family: Montserratblack;

      @media (max-width: 1650px) {
        font-size: 55px;
      }
      @media (max-width: 1200px) {
        font-size: 40px;
      }
      @media (max-width: 660px) {
        font-size: 35px;
      }
      @media (max-width: 480px) {
        margin-top: 2%;
        font-size: 24px;
      }
    }
  }

  &__text {
    color: #595758;
    font-size: $fhhealth-font-SZ-43;
    margin-top: 25px;
    @media (max-width: 660px) {
      font-size: $fhhealth-font-SZ-25;
    }
  }
  &__background-img {
    max-width: 100%;
    display: flex;
  }
  &__text-dot {
    color: #595758;
    font-size: 66px;
    font-weight: bold;
    color: #595758;
    margin-bottom: 20px;
    @media (max-width: 508px) {
      margin-bottom: 0px;
    }

    @media (max-width: 660px) {
      font-size: $fhhealth-font-SZ-25;
    }
    @media (max-width: 1136px) {
      font-size: 35px;
    }
  }
  &__text-info {
    color: #595758;
    font-size: $fhhealth-font-SZ;
    font-weight: 400;
    color: #595758;
    width: 55%;
    margin-top: 1%;
    font-family: OpenSansExtra;
    @media (max-width: 1700px) {
      width: 95%;
      font-size: 16px;
    }
  }
  &__transfer {
    position: absolute;
    left: 0px;
    top: 25%;
    width: 400px;

    @media (max-width: 1700px) {
      width: 350px;
    }
    @media (max-width: 900px) {
      display: none;
    }
  }

  &__RobertChange-country {
    @include setDisplay(flex, start, center, row);
    flex-wrap: wrap;
    color: #575ef9;
    font-size: $fhhealth-font-SZ;
    font-family: OpenSansExtra;
    font-style: normal;
    font-weight: 800;
    position: relative;
    right: 10px;
    padding: 3% 20% 0% 0%;
    @media (max-width: 1700px) {
      padding: 0px;
    }
    @media (max-width: 660px) {
      font-size: 15px;
    }
    div {
      margin: 10px;
    }
  }

  &__video-sz {
    height: 600px;

    margin-right: 50px;
    margin-top: 10%;
    @media (max-width: 1400px) {
      height: 400px;
      margin-top: 5%;
    }
    @media (max-width: 1200px) {
     height: 300px;
      margin-top: 0%;
    }
    @media (max-width: 1000px) {
    height: 300px;
    }
    @media (max-width: 800px) {
    height: 200px;
    }
   
  }
}
