// colors
$fhhealth-color-white: #ffffff;
$fhhealth-color-dark-black: #232325;
$fhhealth-color-black: #27303b;
$fhhealth-color-light-black: #070d27;
$fhhealth-color-dark-bg: #202832;

$fhhealth-color-grey-bg: #f8f7f6;
$fhhealth-border-color: rgba(162, 174, 191, 0.5);
$fhhealth-color-dark-grey-bg: #ada9a9de;
$fhhealth-color-neutral-bg: #eceff1;

$fhhealth-color-carcass-border: #b9babd;
$fhhealth-color-solid-border: #eaedf0;
$fhhealth-color-dashed-border: #bfbebf;
$fhhealth-color-test-analysis-data-border: #d2d3d4;
$fhhealth-color-lab-info-border: #e4e7e9;
$fhhealth-color-dark-gray-border: #595758;
$fhhealth-color-orange: #e85f00;
$fhhealth-color-yellow: #ffeebf;
$fhhealth-color-dark-yellow: #ffb439;
$fhhealth-color-light-gray-text: #7f7a7a;

$fhhealth-color-dark-blue-bg: #72b5fd;
$fhhealth-color-blue-bg: #e6f1fc;
$fhhealth-color-blue-btn: #007aff;

$fhhealth-color-dark-red-bg: #ff7c89;
$fhhealth-color-red-bg: #ffe2e5;
$fhhealth-color-danger: #ff394d;
$fhhealth-color-red-btn: #ff394d;

$fhhealth-color-dark-green-bg: #89d3a4;
$fhhealth-color-green-bg: #dfefe4;
$fhhealth-color-green-btn: #52c17c;
$fhhealth-color-limegreen-btn: #32cd32;
$fhhealth-color-gold: #ffd700;

// font
$fhhealth-font-SF-bold: 'SF-Bold', serif;
$fhhealth-font-SF-medium: 'SF-Medium', serif;

$fhhealth-font-PT-bold: 'PTSerif-Bold', serif;

$fhhealth-font-DM-bold: 'DMSans-Bold', serif;
$fhhealth-font-DM-medium: 'DMSans-Medium', serif;
$fhhealth-font-DM-regular: 'DMSans-Regular', serif;

// Medias
$fhhealth-media-xs: 429.98px;
$fhhealth-media-sm: 826.98px;
$fhhealth-media-md: 949.98px;
$fhhealth-media-lg: 1059.98px;
$fhhealth-media-extra-lg: 1599.98px;

//Image size
$fhhealth-image-width: 231px;
$fhhealth-image-height: 53px;
$fhhealth-image-height-f: 45px;
$fhhealth-image-width-f: 192px;
$fhhealth-image-height-shadow: 1070px;
$fhhealth-image-height-shadow-exchange: 1108px;
//font size
$fhhealth-font-SZ: 18px;
$fhhealth-font-SZ-16: 16px;
$fhhealth-font-SZ-20: 20px;
$fhhealth-font-SZ-25: 25px;
$fhhealth-font-SZ-30: 30px;
$fhhealth-font-SZ-43: 43px;
$fhhealth-font-SZ-70: 70px;
$fhhealth-font-SZ-50: 50px;
