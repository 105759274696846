.reasons {
  position: relative;
  @include setDisplay(flex, center, center, row);
 // position: absolute;
  // top: 18%;
  // left: 5%;
  // @media (max-width: 1700px) {
  //   top: 5%;
  // }
  // @media (max-width: 1100px) {
  //   top: 0%;
  // }
  // @media (max-width: 500px) {
  //   position: relative;
  //   top: 0%;
  //   left: 0%;
  // }
  &__soon-pos {
    text-align: center;
    @media (max-width: 500px) {
      margin: 30px auto;
     
    }
  }
  &__soon {
    background-color: white;
    //color: #e85f00;
    font-size: 29px;
    font-family: Montserratblack;
    padding: 10px 350px 10px 350px;
    text-align: center;
   
  }
  &__shadow{
    display: none;
   z-index: -8;
    @media (max-width: 500px) {
    display: inline-block;
    position:absolute;
    left: 0px;
    bottom: 0px;
    height: 500px;
    max-width: auto;
    }
  }
  &__design {
    
    color: #595758;
    margin-top: 35px;
    @include setDisplay(flex, center, center, row);
    @media (max-width: 850px) {
      margin-top: 1px;
    }
    @media (max-width: 500px) {
      margin-left: 3%;
    }
  }
;

  font-family: Montserrat;
  &__border {
 background-color: #595758;
    border: 2px solid #595758;
    height: 400px;
    @media (max-width: 1604px) {
      height: 340px;
    }
    @media (max-width: 1520px) {
      height: 260px;
    }
    @media (max-width: 1250px) {
      height: 190px;
    }
    @media (max-width: 570px) {
      height: 180px;
    }
    @media (max-width: 500px) {
      display: none;
    }
  }

  &__RobertChange-h1 {
    font-size: $fhhealth-font-SZ-70;
    font-family: Montserratblack;
    font-weight: 800;
    text-transform: uppercase;
    color: #575EF9;
    @media (max-width: 1600px) {
      font-size: 45px;
    }
    @media (max-width: 1100px) {
      font-size: 40px;
    }
    @media (max-width: 950px) {
      font-size: 30px;
    }
    @media (max-width: 690px) {
      font-size: 20px;
    }
    @media (max-width: 600px) {
      font-size: 13px;
      line-height: 14px;
    }
    @media (max-width: 500px) {
      font-size: 24px;
      line-height: 30px;
      margin: 0 auto;
      margin-top: 9%;
      p {
        text-align: center;
      }
    }
  }
  &__info {
    @include setDisplay(flex, flex-start, flex-start, column);
  }
  &__position {
    @include setDisplay(flex, flex-start, flex-start, column);
  
  }
  &__list {
    font-size: 22px;
    font-family: Montserratblack;

    @media (max-width: 1650px) {
      font-size: 18px;
    }
    @media (max-width: 1500px) {
      font-size: 14px;
    }
    @media (max-width: 1250px) {
      font-size: 10px;
    }
    @media (max-width: 570px) {
      font-size: 11px;
      line-height: 9px;
    }
    @media (max-width: 500px) {
      font-size: 21px;
      line-height: 27px;
    }
  }
}
