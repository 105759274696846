.company {
  font-family: OpenSansExtra;

  @media (max-width: 1700px) {
    margin-top: 5px;
  }
  @media (max-width: 700px) {
    margin-top: 80px;
  }

  &__phons-img {
    width: 100%;
    height: 568px;
    max-width: auto;
    @media (max-width: 1600px) {
      height: 488px;
    }
    @media (max-width: 1350px) {
      height: 300px;
    }
    @media (max-width: 950px) {
      height: 200px;
    }
  }
  &__position {
    min-height: 100vh;
    padding: 80px;
    @include setDisplay(flex, space-around, center, row);
    @media (max-width: 1000px) {
      flex-wrap: wrap;
      min-height: auto;
    }

    @media (max-width: 500px) {
      min-height: auto;
      padding: 10px;
    }
  }
  &__phons-mob {
    display: flex;
    display: none;
    width: 100%;
    @media (max-width: 500px) {
      display: inline-block;
      display: flex;
    }
  }
  &__currency-img {
    height: 600px;
    max-width: auto;
    @media (max-width: 1600px) {
      height: 550px;
    }
    @media (max-width: 1350px) {
      height: 400px;
    }
    @media (max-width: 950px) {
      display: none;
    }
  }
  &__RobertChange-info {
    @include setDisplay(flex, flex-start, flex-start, column);

    h1 {
      color: #575ef9;
      font-size: $fhhealth-font-SZ-70;
      line-height: 85.33px;
      font-family: OpenSansExtra;
      font-weight: bolder;
      @media (max-width: 1400px) {
        font-size: 50px;
      }
      @media (max-width: 900px) {
        font-size: 25px;
      }
      @media (max-width: 600px) {
        font-size: 24px;
      }
      @media (max-width: 500px) {
        font-size: 24px;
        margin-top: 14%;
      }
    }
  }
  &__RobertChange-info2 {
    @include setDisplay(flex, flex-start, flex-start, column);
    @media (max-width: 500px) {
      padding: 25px;
    }

    h1 {
      color: #575ef9;
      font-size: $fhhealth-font-SZ-70;
      line-height: 85.33px;
      font-family: OpenSansExtra;
      font-weight: bolder;
      @media (max-width: 1400px) {
        font-size: 50px;
        line-height: 55px;
      }
      @media (max-width: 900px) {
        font-size: 35px;
      }
      @media (max-width: 600px) {
        font-size: 30px;
      }
      @media (max-width: 500px) {
        font-size: 22px;
        margin-top: 10%;
      }
    }
  }
  &__info-pos {
    @include setDisplay(flex, center, center, column);

    width: 60%;
    padding: 1% 3% 3% 9%;

    @media (max-width: 1320px) {
      padding: 0px;
      width: 90%;
    }
  }
  &__info-pos2 {
    @include setDisplay(flex, center, center, column);
    margin-left: 10%;
    @media (max-width: 1400px) {
      width: 65%;
    }
    @media (max-width: 1320px) {
      width: 70%;
    }
    @media (max-width: 890px) {
      width: 85%;
    }
    @media (max-width: 490px) {
      width: 90%;
    }
  }
  &__fraud {
    color: #595758;
    font-size: $fhhealth-font-SZ-50;
    line-height: 60.95px;
    font-weight: 900;
    margin-top: 15px;

    @media (max-width: 1320px) {
      font-size: 28px;
      line-height: 47px;
      margin-top: 6%;
    }
    @media (max-width: 700px) {
      font-size: 18px;
      line-height: 34px;
    }
    @media (max-width: 600px) {
      font-size: 12px;
      line-height: 38px;
      margin-top: 4%;
    }
    p {
      font-family: OpenSansExtra;
      @media (max-width: 700px) {
        font-size: 17px;
        line-height: 34px;
      }
      @media (max-width: 500px) {
        font-size: 14px;
        line-height: 22px;
      }
    }
  }
  &__text-inform {
    color: #595758;
    font-size: $fhhealth-font-SZ;
    width: 80%;
    margin-top: 2%;
    font-weight: 400;
    font-family: OpenSansExtra;
    @media (max-width: 1200px) {
      font-size: 16px;
      line-height: 20px;
      width: 100%;
    }
  }

  &__shadow-exchange {
    @include setDisplay(flex, center, center, column);
    background-color: rgba(255, 255, 255, 0.41);
    text-align: center;
    width: 45%;
    height: 100%;
    position: absolute;
    right: 0px;
    top: 0px;
  }

  &__texts {
    color: #595758;
    font-weight: bold;
    color: #595758;
    font-size: 73px;
    margin-bottom: 20px;
    @media (max-width: 1300px) {
      font-size: 40px;
    }
    @media (max-width: 933px) {
      display: none;
    }
  }
  &__shadow-position {
    @include setDisplay(flex, flex-start, flex-start, column);
    padding-left: 20%;
  }
  &__title {
    color: $fhhealth-color-orange;
    text-transform: uppercase;
    font-weight: bold;
    font-size: $fhhealth-font-SZ-70;
  }

  &__text {
    color: #595758;
    font-size: $fhhealth-font-SZ-43;
    margin-top: 25px;
    @media (max-width: 660px) {
      font-size: $fhhealth-font-SZ-25;
    }
  }
  &__background-img {
    max-width: 100%;
    display: flex;
  }

  &__text-info {
    color: #595758;
    font-size: 18px;
    font-weight: 400;
    color: #595758;
    margin-top: 5%;
    width: 55%;
    font-family: OpenSansExtra;

    @media (max-width: 1407px) {
      width: 81%;
      font-size: 16px;
    }
    @media (max-width: 1307px) {
      font-size: 15px;
    }
    @media (max-width: 1000px) {
      width: 99%;
    }
    @media (max-width: 700px) {
      font-size: 15px;
      line-height: 15px;
      margin-top: 4px;
    }
    @media (max-width: 500px) {
      font-size: 16px;
      line-height: 20px;
      margin-top: 3%;
    }
  }
  &__mission {
    min-height: 100vh;
    padding: 80px;
    @include setDisplay(flex, space-around, center, row);
    @media (max-width: 1000px) {
      flex-wrap: wrap;
      min-height: auto;
    }
    @media (max-width: 500px) {
      min-height: auto;
      padding: 10px;
    }
  }
  &__currency-title {
    color: $fhhealth-color-orange;
    font-family: OpenSansExtra;
    font-style: normal;
    font-weight: 900;
    font-size: $fhhealth-font-SZ-70;
    line-height: 85px;
    text-transform: uppercase;
  }
  &__country {
    @include setDisplay(flex, center, center, row);
    flex-wrap: wrap;
    color: $fhhealth-color-orange;
    font-size: $fhhealth-font-SZ;
    font-family: OpenSansExtra;
    font-style: normal;
    font-weight: 800;
    position: relative;
    right: 10px;
    padding: 15px 0px 10px 0px;
    @media (max-width: 660px) {
      font-size: 25px;
    }
    div {
      margin: 10px;
    }
  }
  &__RobertChange-app-info {
    width: 30%;
    @media (max-width: 1700px) {
      width: 40%;
    }
    @media (max-width: 900px) {
      width: 90%;
      margin-top: 10%;
      padding: 25px;
    }

    p {
      color: #575ef9;
      font-size: $fhhealth-font-SZ-70;
      line-height: 85.33px;
      font-family: OpenSansExtra;
      font-weight: bolder;
      @media (max-width: 1500px) {
        font-size: 45px;
        line-height: 50px;
      }
      @media (max-width: 900px) {
        font-size: 35px;
        line-height: 35px;
      }
      @media (max-width: 650px) {
        font-size: 22px;
        line-height: 25px;
      }
    }
    ul {
      font-size: 18px;
      font-weight: 700;
      line-height: 22px;
      font-family: OpenSansExtra;
      @media (max-width: 1000px) {
        font-size: 17px;
        line-height: 20px;
        font-weight: 500;
        color: #595758;
      }
    }
  }
  &__li-pos {
    position: relative;
    right: 4%;
    li {
      color: #595758;
    }
    @media (max-width: 600px) {
      right: 12%;
    }
  }
  &__text-mob1 {
    display: none;

    @media (max-width: 500px) {
      display: inline-block;
    }
  }
  &__text-mob2 {
    display: none;
    background-color: #fffaf6;
    height: 80vh;
    @media (max-width: 500px) {
      display: inline-block;
    }
  }
}
