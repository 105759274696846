.position {
  min-height: 100vh;
  @include setDisplay(flex, center, center, row);
  padding: 50px;
  @media (max-width: 900px) {
   min-height: auto;
   padding: 80px;
  }
}
.thankyou {
  padding: 50px;
  @media (max-width: 900px) {
    padding: 0px;
  }

  &__RobertChange-tittle {
    color: #575ef9;
    font-size: 70px;
    font-weight: bolder;
    font-family: Montserratblack;
    @media (max-width: 600px) {
      font-size: 24px;
    }
  }
  &__info {
    color: #595758;
    font-size: 18px;
    font-weight: 700;
    @media (max-width: 660px) {
      font-size: 16px;
    }
  }

  &__text-dot-message {
    color: #595758;
    font-size: 66px;
    font-weight: bold;
    color: #595758;
    margin-bottom: 20px;

    @media (max-width: 1136px) {
      font-size: 50px;
    }
    @media (max-width: 700px) {
      font-size: 40px;
    }
    @media (max-width: 500px) {
      font-size: 25px;
    }
  }
  &__img-mob {
    display: none;
    position: absolute;
    left: 5%;
    top: -20%;
    @media (max-width: 950px) {
      display: inline-block;
    }
  }
  &__img-m {
    width: 100%;
    height: 450px;
    max-width: auto;
    @media (max-width: 1600px) {
      height: 348px;
    }
    @media (max-width: 1350px) {
      height: 200px;
    }
    @media (max-width: 950px) {
      display: none;
    }
  }
}
